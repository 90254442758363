import { IoMdArrowBack } from "react-icons/io";
import { Row, Col, Button } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";

import { fetchOrders, completeOrder } from "../apis";
import AuthContext from "../contexts/AuthContext";
import MainLayout from "../layouts/MainLayout";
import Order from "../components/Order";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const params = useParams();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [showCompleted, setShowCompleted] = useState(false);

  const onBack = () => navigate(`/places/${params.id}`);

  const onFetchOrders = async () => {
    const json = await fetchOrders(params.id, auth.token);
    if (json) {
      setOrders(json);
    }
  };

  const onCompleteOrder = async (orderId) => {
    const json = await completeOrder(
      orderId,
      { status: "completed" },
      auth.token
    );
    if (json) {
      onFetchOrders();
    }
  };

  useEffect(() => {
    onFetchOrders();
    const interval = setInterval(() => {
      onFetchOrders();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const filterOrders = () => {
    let filteredOrders;
    if (showCompleted) {
      filteredOrders = orders.filter((order) => order.status === "completed");
    } else {
      filteredOrders = orders.filter((order) => order.status === "processing");
    }

    return filteredOrders.sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      return dateB.getTime() - dateA.getTime();
    });
  };

  const handleShowCompleted = () => {
    setShowCompleted(true);
  };

  const handleShowInProgress = () => {
    setShowCompleted(false);
  };

  return (
    <MainLayout>
      <div className="d-grid justify-content-between align-items-center mb-4">
        <div
          style={{ placeContent: "center", marginBottom: "20px" }}
          className="d-flex align-items-center"
        >
          <Button variant="link" onClick={onBack}>
            <IoMdArrowBack size={25} color="black" />
          </Button>
          <h3 className="mb-0 ml-2 mr-2">My Orders</h3>
        </div>
        <div style={{ display: "flex", gap: "10px", placeContent: "center" }}>
          <Button onClick={handleShowCompleted} variant="standard">
            Show Completed
          </Button>
          <Button onClick={handleShowInProgress} variant="standard">
            Show In Progress
          </Button>
        </div>
      </div>

      <Row className="justify-content-center">
        {filterOrders().map((order) => (
          <Col key={order.id} lg={4} md={6}>
            <Order order={order} onComplete={() => onCompleteOrder(order.id)} />
          </Col>
        ))}
      </Row>
    </MainLayout>
  );
};

export default Orders;
