import "../index.css";
import React, { useEffect, useContext, createContext, useState } from "react";
import { fetchPlace } from "../apis";
import AuthContext from "../contexts/AuthContext";

const PlaceContext = createContext("");

export function PlaceProvider({ children }) {
  const [place, setPlace] = useState([]);
  const auth = useContext(AuthContext);
  const [placeId, setPlaceId] = useState("");

  const onFetchPlace = async () => {
    if (placeId) {
      const json = await fetchPlace(placeId, auth.token);
      if (json) {
        setPlace(json);
      }
    }
  };

  useEffect(() => {
    onFetchPlace();
  }, [placeId]);

  return (
    <PlaceContext.Provider value={{ setPlace, place, placeId, setPlaceId }}>
      {children}
    </PlaceContext.Provider>
  );
}
export default PlaceContext;
