import { Col, Button } from "react-bootstrap";
import React, { useContext } from "react";
import styled from "styled-components";
import { BiEdit } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import LanguageContext from "../contexts/LanguageContext";
import Translation from "./Translation";

const Container = styled.div`
  border-radius: 10px;
  background-color: white;
  height: 80px;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  opacity: ${({ active }) => (active ? 1 : 0.6)};
  > div:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-size: cover;
  }
  > div:last-child {
  }
`;

const MenuItem = ({ item, onEdit, onRemove, color }) => {
  const { language } = useContext(LanguageContext);
  const { translate } = Translation({ language });

  return (
    <Container active={item.is_available}>
      <Col
        xs={5}
        style={{
          backgroundImage: `url(${item.image})`,
          backgroundPositionY: "center",
          backgroundPositionX: "center",
          maxWidth: "35%",
        }}
      />
      <Col
        className="d-flex flex-column  justify-content-between position-relative"
        style={{ width: "65%", overflow: "hidden" }}
      >
        <div>
          <div className="d-flex justify-content-between flex-row align-items-center mb-2">
            <b
              className="itemName"
              style={{
                color,
              }}
            >
              {item["name_" + language]}
            </b>
            <div className="d-flex">
              {onEdit ? (
                <Button variant="link" onClick={onEdit}>
                  <BiEdit size={20} />
                </Button>
              ) : null}

              {onRemove ? (
                <Button variant="link" onClick={onRemove}>
                  <AiOutlineDelete size={20} color="red" />
                </Button>
              ) : null}
            </div>
          </div>
        </div>
        <div style={{ color, textAlign: "end" }}>
          {onEdit ? (
            <>
              {item.variants ? (
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    placeContent: "end",
                  }}
                >
                  {item.variants.map((variant) => (
                    <div
                      key={variant.variant}
                      style={{
                        display: "flex",
                        alignSelf: "end",
                        flexDirection: "row",
                      }}
                    >
                      <b>{variant.variant}:</b>
                      <b>{variant.price}€</b>
                    </div>
                  ))}
                </div>
              ) : (
                <b style={{ alignSelf: "end" }}>{item.price}€</b>
              )}
            </>
          ) : (
            <b
              className="itemPrice"
              style={{ color, fontSize: "15px", paddingLeft: "50px" }}
            >
{item.variants ? (
  item.variants.length > 3 ? (
  translate('choose') + " "  + translate("flavor").toLowerCase()
  ) : (
    translate("chooseSize")
  )
) : (
  item.price + "€"
)}            </b>
          )}
        </div>
        <div className="d-flex justify-content-between align-items-end align-self-end">
          {!item.is_available ? (
            <small className="text-secondary">Not Available</small>
          ) : null}
        </div>
      </Col>
    </Container>
  );
};

export default MenuItem;
