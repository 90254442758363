import React, { useState, useEffect } from "react";

const OrderTime = ({ orderTime }) => {
  const [elapsedTime, setElapsedTime] = useState("");

  useEffect(() => {
    const updateElapsedTime = () => {
      const currentTime = new Date().getTime();
      const orderTimestamp = new Date(orderTime).getTime();

      const diffInMilliseconds = currentTime - orderTimestamp;
      const elapsedMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
      const elapsedHours = Math.floor(elapsedMinutes / 60);
      const elapsedDays = Math.floor(elapsedHours / 24);
      const elapsedWeeks = Math.floor(elapsedDays / 7);

      let timeString = "";

      if (elapsedWeeks > 0) {
        timeString = `${elapsedWeeks} week${elapsedWeeks > 1 ? "s" : ""} ago`;
      } else if (elapsedDays > 0) {
        timeString = `${elapsedDays} day${elapsedDays > 1 ? "s" : ""} ago`;
      } else if (elapsedHours > 0) {
        timeString = `${elapsedHours} hour${elapsedHours > 1 ? "s" : ""} ago`;
      } else if (elapsedMinutes > 0) {
        timeString = `${elapsedMinutes} minute${
          elapsedMinutes > 1 ? "s" : ""
        } ago`;
      } else {
        timeString = "Just now";
      }

      setElapsedTime(timeString);
    };

    updateElapsedTime(); // Initial call to set the elapsed time immediately

    const interval = setInterval(updateElapsedTime, 60000); // Update the elapsed time every minute

    return () => clearInterval(interval); // Clear the interval when the component is unmounted
  }, [orderTime]);

  return (
    <span
      style={{ fontSize: "14px" }}
      className="orderTime badge badge-info position-absolute top-[-2px] mb-2"
    >
      {elapsedTime}
    </span>
  );
};

export default OrderTime;
