import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useContext } from "react";
import LanguageContext from "../contexts/LanguageContext";
import { Button } from "react-bootstrap";
import ikonicamenu from "../photos/ikonicamenu.png";
import cancel from "../photos/cancel.png";

export default function Item({ item, font, onOrder, onDone, ordering }) {
  const language = useContext(LanguageContext);
  const itemVariants = item.variants;
  const waiterLogged = localStorage.getItem("waiterLogged");
  const [addedVariant, setAddedVariant] = useState({
    price: itemVariants?.[0].price,
    variant: itemVariants?.[0].variant,
  });

  let desc = item["description_" + language.language];

  const Div = styled.div`
    b, p {
      ${({ font }) => font && `font-family: ${font};`}
    },
  `;

  const handleVariantChange = (event) => {
    const selectedIndex = event.target.selectedIndex;
    const selectedVariant = itemVariants[selectedIndex];
    setAddedVariant({
      price: selectedVariant.price,
      variant: selectedVariant.variant,
    });
  };

  useEffect(() => {
    if (itemVariants) {
      item.price = addedVariant?.price;
    }
  }, [addedVariant, itemVariants, item]);

  console.log(addedVariant);
  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "45px",
          display: "flex",
          flexDirection: "column",
          maxHeight: "70vh",
          position: "relative",
          overflow: "scroll",
        }}
      >
        <img
          className="cancel-icon"
          onClick={() => onDone()}
          src={cancel}
        ></img>
        <div className="oneitem-root">
          <img className="item-img" alt="item-pic" src={item.image}></img>
        </div>
        <Div
          font={font}
          className={desc ? "oneitem-content" : "oneitem-content-without-desc"}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b className="modalItemName" style={{ width: "70%" }}>
              {item["name_" + language.language]}
            </b>
            <div style={{ display: "flex", alignItems: "center", gap: "17px" }}>
              <b>{itemVariants ? addedVariant?.price : item.price} €</b>
              {(ordering || waiterLogged === "true") && (
                <Button
                  variant="standard"
                  className="order-button-modal"
                  size="sm"
                  onClick={() => {
                    onOrder({ ...item, variants: addedVariant?.variant });
                    onDone();
                  }}
                >
                  <img width={35} height={35} src={ikonicamenu} />
                </Button>
              )}
            </div>
          </div>
          <p
            className="modalItemDescription"
            dangerouslySetInnerHTML={{
              __html: item["description_" + language.language],
            }}
          ></p>
        </Div>
      </div>
      <div className="variants-div">
        {itemVariants &&
          itemVariants.map((variant) => {
            if (variant.variant.length > 3) {
              return null;
            } else {
              return (
                <button
                  key={variant.variant}
                  className={
                    variant.price === addedVariant?.price
                      ? "activeButton rounded-circle border-radius-[15px] bg-black text-white border-0 p-2"
                      : "variantButton rounded-circle bg-black text-white border-0 p-2"
                  }
                  onClick={() =>
                    setAddedVariant({
                      price: variant.price,
                      variant: variant.variant,
                    })
                  }
                  value={addedVariant?.price}
                >
                  {variant.variant}
                </button>
              );
            }
          })}
        {itemVariants &&
          itemVariants.some((variant) => variant.variant.length > 3) && (
            <select
              style={{
                borderRadius: "20px",
                border: "2px solid black",
                display: "flex",
                justifyContent: "center",
                padding: "3px",
                width: "190px",
                cursor: "pointer",
              }}
              value={addedVariant?.variant}
              onChange={handleVariantChange}
            >
              {itemVariants
                .filter((variant) => variant.variant.length > 3)
                .map((variant) => (
                  <option key={variant.variant} value={variant.variant}>
                    {variant.variant}
                  </option>
                ))}
            </select>
          )}
      </div>
    </>
  );
}
