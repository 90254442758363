import React, { useEffect } from "react";
import styled from "styled-components";
import MenuItem from "./MenuItem";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import Item from "./Item";
import { useState } from "react";
import { useContext } from "react";
import LanguageContext from "../contexts/LanguageContext";
import arrow from "../photos/arrow.png";
import { TiShoppingCart } from "react-icons/ti";
import ikonicamenu from "../photos/ikonicamenu.png";
import Translation from "./Translation";

const Container = styled.div`
  b,
  p {
    ${({ font }) => font && `font-family: ${font};`}
  }
`;

const MenuList = ({
  place,
  shoppingCart = {},
  showShoppingCart,
  setShowShoppingCart,
  totalQuantity,
  onOrder,
  font = "",
  color = "",
}) => {
  const categoryFromParams = useParams();

  const { language } = useContext(LanguageContext);
  let [category, setCategory] = useState();
  let [mainCategory, setMainCategory] = useState();

  let mainCategories = useState(
    JSON.parse(localStorage.getItem("mainCategories")) || []
  );
  mainCategories = mainCategories[0];

  const findParentById = (elements, id) => {
    if (!elements && !Array.isArray(elements)) return null;
    for (const element of elements) {
      if (element.id === id) {
        return element;
      } else if (element?.children?.length > 0) {
        const found = findParentById(Array.from(element.children), id);
        if (found) {
          if (!element.parent_category) setMainCategory(element);
          return found;
        }
      }
    }
    return null;
  };

  useEffect(() => {
    const child = findParentById(
      mainCategories,
      parseInt(categoryFromParams.categoryId)
    );
    setCategory(child);
  }, []);

  const navigate = useNavigate();

  const [menuItemFormShow, setMenuItemFormShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const waiterLogged = localStorage.getItem("waiterLogged");

  const showModal = () => setMenuItemFormShow(true);
  const hideModal = () => setMenuItemFormShow(false);
  if (!category) return "";

  const handleChooseSize = (item) => {
    setSelectedItem(item);
    showModal();
  };

  const { translate } = Translation({ language });

  return (
    <Container className="menuContainer" font={font}>
      <div
        style={{
          backgroundImage: `url(${place.background_image})`,
          backgroundColor: "rgba(0,0,0, 0.3)",
          backgroundBlendMode: "darken",
          backgroundSize: "cover",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "30vh",
          backgroundPositionY: "center",
          width: "100vw",
          maxWidth: "480px",
        }}
      >
        <div
          style={{
            height: "35vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {mainCategory ? 
          ( <b
            className="parentCategoryName"
            style={{ color: "white", fontSize: "23px" }}
            >
              {mainCategory["name_" + language]}
            </b>
            ): (
            <img
            style={{ maxWidth: "60%", marginBottom: "10vh" }}
            src={place.image}
            alt="placeImage"
          ></img>)
            }
        </div>
      </div>
      <div
        style={{
          position: "relative",
          backgroundColor: `${place.background_color}`,
          paddingTop: "70px",
          minHeight: "80vh",
        }}
        className="onlineMenu"
      >
        <img
          src={arrow}
          alt="arrow"
          style={{
            position: "absolute",
            left: "5%",
            top: "30px",
            width: "28px",
            height: "25px",
            cursor: "pointer",
          }}
          onClick={() => navigate(-1)}
          className="arrowLeft"
        ></img>
        {
          <div
            style={{
              display: "grid",
              placeContent: "center",
              marginBottom: "25px",
            }}
          >
            <div
              key={category.id}
              style={{
                backgroundImage: `url(${category?.background_image})`,
                backgroundSize: "cover",
                backgroundBlendMode: "darken",
                backgroundColor: "rgba(0,0,0, 0.3)",
                borderColor: place.color_2,
              }}
              className="oneCategory"
            >
              <b style={{ color: "white" }}>{category["name_" + language]}</b>
            </div>
            <div
              key={category.id}
              style={{
                backgroundImage: `url(${category.background_image})`,
                backgroundSize: "cover",
                backgroundBlendMode: "darken",
                backgroundColor: "rgba(0,0,0, 0.3)",
                borderColor: place.color_2,
              }}
            ></div>
          </div>
        }
        <div
          style={{
            textAlign: "-webkit-center",
            display: "grid",
            gap: "15px",
          }}
        >
          {category?.menu_items &&
            category?.menu_items
              .filter((item) => item.is_available)
              .map((item, index, array) => (
                <div style={{ position: "relative" }}>
                  <div
                    key={item}
                    style={{
                      width: "82%",
                      cursor: "pointer",
                      marginBottom: index === array.length - 1 ? "30px" : "0",
                    }}
                    onClick={() => {
                      setSelectedItem(item);
                      showModal();
                    }}
                  >
                    <MenuItem
                      key={item.id}
                      item={{
                        ...item,
                        quantity: shoppingCart[item.id]?.quantity,
                      }}
                      color={color}
                      font={font}
                    />
                  </div>
                  {place.ordering_system || waiterLogged === "true" ? (
                    item.variants ? (
                      <Button
                        variant="standard"
                        className="order-button"
                        size="sm"
                        onClick={() => handleChooseSize(item)}
                      >
                        <img width={35} height={35} src={ikonicamenu} />
                      </Button>
                    ) : (
                      <Button
                        variant="standard"
                        className="order-button"
                        size="sm"
                        onClick={() => onOrder(item)}
                      >
                        <img width={35} height={35} src={ikonicamenu} />
                      </Button>
                    )
                  ) : null}
                </div>
              ))}
        </div>
        <Modal
          id="itemModal"
          className="itemModal"
          show={menuItemFormShow}
          onHide={hideModal}
          centered
        >
          <Modal.Body className="modalBody">
            <Item
              onDone={() => {
                hideModal();
              }}
              onOrder={onOrder}
              ordering={place.ordering_system}
              item={selectedItem}
              font={font}
            />
          </Modal.Body>
        </Modal>
        {console.log(totalQuantity)}
        {(place.ordering_system && totalQuantity) ||
        (waiterLogged === "true" && totalQuantity) ? (
          <div
            className="order-div"
            onClick={() => setShowShoppingCart(!showShoppingCart)}
          >
            <div className="prikazi-narudzbu">
              <span className="text-white ml-4">{translate("showOrder")}:</span>
            </div>
            <div className="quantity-cart-div">
              {!showShoppingCart && (
                <div className="quantity-div">
                  <span className="quantity-text">{totalQuantity}</span>
                </div>
              )}
              <div className="cart-div">
                <button
                  style={{
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    border: "none",
                  }}
                >
                  <TiShoppingCart size={30} color="white" />
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </Container>
  );
};

export default MenuList;
