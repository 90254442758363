import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../contexts/AuthContext";
import { HR, DE, GB, ES, FR, IT, TR } from "country-flag-icons/react/3x2";
import "../index.css";
import LanguageContext from "../contexts/LanguageContext";
import PlaceContext from "../contexts/PlaceContext";
import { useState } from "react";
import { MdFileDownloadDone } from "react-icons/md";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export default function HomeMenu() {
  const { place, setPlaceId } = useContext(PlaceContext);
  const [waiterLogged, setwaiterLogged] = useState(
    localStorage.getItem("waiterLogged")
  );

  const placeId = useParams().id;

  if (place?.id === parseInt(placeId)) {
    localStorage.setItem("placeId", placeId);
  } else {
    localStorage.removeItem("placeId");
    localStorage.setItem("placeId", placeId);
    localStorage.removeItem("shoppingCart");
  }

  let mainCategories = [];

  if (place && place.categories) {
    const data = place.categories;
    const childMap = data.reduce((map, child) => {
      return {
        ...map,
        [child.id]: {
          ...child,
        },
      };
    }, {});

    Object.values(childMap).forEach((child) => {
      if (child.parent_category) {
        if (childMap[child.parent_category]) {
          const parent = childMap[child.parent_category];
          if (!parent.children) {
            parent.children = [];
          }
          parent.children.push(child);
        }
      } else {
        mainCategories.push(child);
      }
    });
  }

  const navigate = useNavigate();
  const language = useContext(LanguageContext);
  const [showLogin, setShowLogin] = useState(false);
  const [password, setPassword] = useState("");

  const languages = [
    {
      name: "Hrvatski",
      img: <HR className="languageIcons" style={{ width: "50px" }}></HR>,
      langKey: "bs",
    },
    {
      name: "English",
      img: <GB className="languageIcons" style={{ width: "50px" }}></GB>,
      langKey: "en",
    },
    {
      name: "Deutsch",
      img: <DE className="languageIcons" style={{ width: "50px" }}></DE>,
      langKey: "de",
    },
    {
      name: "Español",
      img: <ES className="languageIcons" style={{ width: "50px" }}></ES>,
      langKey: "es",
    },
    {
      name: "Français",
      img: <FR className="languageIcons" style={{ width: "50px" }}></FR>,
      langKey: "fr",
    },
    {
      name: "Italiano",
      img: <IT className="languageIcons" style={{ width: "50px" }}></IT>,
      langKey: "it",
    },
    {
      name: "Türkçe",
      img: <TR className="languageIcons" style={{ width: "50px" }}></TR>,
      langKey: "tr",
    },
  ];

  // const onFetchPlace = async () => {
  //   const json = await fetchPlace(params.id, auth.token);
  //   if (json) {
  //     setPlace(json);
  //   }
  // };

  useEffect(() => {
    setPlaceId(placeId);
    const today = new Date();
    localStorage.setItem("storedDate", today.toISOString());
    const storedDate = localStorage.getItem("storedDate");
    const storedDateObj = new Date(storedDate);
    const isToday = storedDateObj.toDateString() === today.toDateString();

    if (!isToday) {
      localStorage.removeItem("shoppingCart");
    }
  }, []);

  useEffect(() => {
    function updateLocalStorage() {
      localStorage.setItem("mainCategories", JSON.stringify(mainCategories));
    }
    updateLocalStorage();
  }, [mainCategories]);

  function handleClick(lang) {
    language.setLanguage(lang);
    localStorage.setItem("dataKey", lang);
    navigate(`/menu/${place.id}/categories`);
    const localPlace = JSON.stringify(place);
    localStorage.setItem("place", localPlace);
  }

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const submitPassword = async () => {
    setShowLogin(!showLogin);

    if (password === place.password) {
      setwaiterLogged("true");
      localStorage.setItem("waiterLogged", "true");
      toast("Successfully logged in", { type: "success" });
    }
  };

  const showPasswordInput = () => {
    setShowLogin(!showLogin);
    setPassword("");
  };

  const waiterLogout = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You are about to log out. This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, log out",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      setPassword("");
      localStorage.removeItem("waiterLogged");
      setwaiterLogged();
    }
  };

  return (
    <>
      <div
        className="homeMenu"
        style={{
          backgroundColor: "rgba(0,0,0, 0.6)",
          backgroundImage: `url(${place.background_image})`,
          backgroundBlendMode: "darken",
        }}
      >
        <img src={place?.image}></img>
        <div className="languages">
          {languages.map((lang) => {
            return (
              place.enabled_languages &&
              place.enabled_languages.includes(lang.langKey) && (
                <button
                  key={lang.langKey}
                  className="chooseLanguage"
                  value={lang.langKey}
                  onClick={(lang) => handleClick(lang.target.value)}
                >
                  {lang.img}
                  {lang.name}
                </button>
              )
            );
          })}
        </div>
        <div>
          <p
            style={{
              color: "white",
              fontSize: "15px",
              font: "lato, sans-serif",
            }}
          >
            <span style={{ marginRight: "5px" }}>podrska.ba</span>
            {showLogin ? (
              <div
                className="d-flex align-items-center"
                style={{ gap: "10px" }}
              >
                <div className="inputNapomenaDiv">
                  <input
                    placeholder="write password"
                    autoFocus
                    style={{ width: "100%", borderRadius: "25px" }}
                    className="form-control"
                    type="password"
                    value={password}
                    onChange={(e) => onPasswordChange(e)}
                  ></input>
                </div>
                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                  }}
                >
                  <MdFileDownloadDone onClick={submitPassword} size="sm" />
                </div>
              </div>
            ) : waiterLogged === "true" ? (
              <a onClick={waiterLogout}>©</a>
            ) : (
              <a onClick={showPasswordInput}>©</a>
            )}
          </p>
        </div>
      </div>
    </>
  );
}
