import { useNavigate } from "react-router";
import correct from "../photos/correct.png";
import Translation from "./Translation";
import { useContext } from "react";
import LanguageContext from "../contexts/LanguageContext";

export default function OrderAcceptModal() {
  const navigate = useNavigate();
  const { language } = useContext(LanguageContext);
  const { translate } = Translation({ language });
  return (
    <div
      style={{
        display: "grid",
        justifyContent: "center",
        padding: "20px",
        borderRadius: "40px",
      }}
    >
      <img
        src={correct}
        width={"150px"}
        style={{ display: "flex", justifySelf: "center" }}
      />
      <p style={{ fontSize: "2.5vh", textAlign: "center" }}>
        {translate("orderRecieved")}
      </p>
      <button
        onClick={() => navigate(-1)}
        style={{
          backgroundColor: "black",
          color: "white",
          borderRadius: "10px",
          border: "none",
          cursor: "pointer",
          width: "200px",
          padding: "5px",
          justifySelf: "center",
        }}
      >
        {translate("backToMenu")}
      </button>
    </div>
  );
}
