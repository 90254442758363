import { Button, Form, Tab, Tabs } from "react-bootstrap";
import { BA, DE, GB, ES, FR, IT, TR, HR } from "country-flag-icons/react/3x2";
import { useEffect, useState, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";

const LanguageTabContent = ({
  item,
  langKey,
  language,
  clearInputs,
  nameInputRef,
  descriptionInputRef,
  translation,
  setTranslation,
}) => {
  const [name, setName] = useState(item[`name_${langKey}`] ?? "");

  const [description, setDescription] = useState(
    item[`description_${langKey}`] ?? ""
  );

  const [translating, setTranslating] = useState(false);

  const languages = "Croatian, English, German, Spain, French, Italian, Turkish";

  useEffect(() => {
    if (translating) {
      Object.keys(translation).forEach((langKey) => {
        item[langKey] = translation[langKey];
      });
    } else {
      item[`name_${langKey}`] = name;
    }
  }, [name, translation, langKey]);

  useEffect(() => {
    if (translating) {
      Object.keys(translation).forEach((langKey) => {
        item[langKey] = translation[langKey];
      });
    } else {
      item[`description_${langKey}`] = description;
    }
  }, [description, translation, langKey]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      ["bold", "italic", "underline", "strike", "blackquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
    ],
  };

  const generateDescriptionTranslation = () => {
    if (
      descriptionInputRef?.current?.value.length > 0 &&
      descriptionInputRef?.current?.value !== "<p><br></p>"
    )
      return `then also separately, give me translation of this ${descriptionInputRef?.current?.value} as follows:
      description_bs: translation
      description_en: translation
      description_de: translation
      description_es: translation
      description_fr: translation
      description_it: translation
      description_tr: translation`;
  };

  const translate = async () => {
    setTranslating(true);
    try {
      const message = `give me translation of ${
        nameInputRef.current?.value
      } on following languages ${languages} as follows
      name_bs: translation
      name_en: translation
      name_de: translation
      name_es: translation
      name_fr: translation
      name_it: translation
      name_tr: translation
      ${
        descriptionInputRef?.current?.value.length < 1 ||
        descriptionInputRef?.current?.value !== "<p><br></p>"
          ? generateDescriptionTranslation()
          : null
      }
      `;
      const res = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer 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",
        },
        body: JSON.stringify({
          messages: [{ role: "system", content: message }],
          model: "gpt-3.5-turbo",
        }),
      });

      const json = await res.json();

      const translationList = {};
      json.choices[0].message.content.split("\n\n").forEach((translation) => {
        translation.split("\n").forEach((translation) => {
          const [langCode, value] = translation.split(":");
          translation.trim(" ");
          translationList[langCode.trim()] = value?.trim();
        });
      });
      await setTranslation(translationList);
    } finally {
      toast("Tekst je preveden", { type: "success" });
      setTranslating(false);
    }
  };

  useEffect(() => {
    setName(translation[`name_${langKey}`] ?? item[`name_${langKey}`]);
    setDescription(
      translation[`description_${langKey}`] ?? item[`description_${langKey}`]
    );
  }, [item, translation, langKey]);

  useEffect(() => {
    if (clearInputs) {
      setName("");
      setDescription("");
      setTranslation("");
    }
  }, [clearInputs]);

  return (
    <>
      <Form.Group controlId={`${language}-form`}>
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          as="input"
          placeholder={`Enter name in ${language}`}
          name={`name_${langKey}`}
          key={`name_${langKey}`}
          defaultValue={name}
          ref={nameInputRef}
          onChange={(e) => setName(e.target.value)}
        />
        <Form.Label>Description</Form.Label>
        <ReactQuill
          theme="snow"
          placeholder={`Enter description in ${language}`}
          name={`description_${langKey}`}
          ref={descriptionInputRef}
          key={`description_${langKey}`}
          defaultValue={description}
          onChange={setDescription}
          modules={modules}
        />
      </Form.Group>
      <Button
        style={{ marginBottom: "10px" }}
        variant="standard"
        type="submit"
        onClick={translate}
      >
        Translate
      </Button>
    </>
  );
};
export default function LanguageTabs({ place, item, clearInputs }) {
  const font = place.font;

  const [translation, setTranslation] = useState("");

  const nameInputRef = useRef();
  const descriptionInputRef = useRef();

  return (
    <>
      <Tabs
        id="controlled-tab-example"
        className="mb-3"
        style={{ display: "grid", gridAutoFlow: "column", textAlign: "center" }}
      >
        <Tab
          eventKey="lang-bs"
          title={
            <HR
              title="Croatia"
              style={{ width: "20px", paddingBottom: "5px" }}
            ></HR>
          }
        >
          <LanguageTabContent
            translation={translation}
            setTranslation={setTranslation}
            item={item}
            clearInputs={clearInputs}
            nameInputRef={nameInputRef}
            descriptionInputRef={descriptionInputRef}
            langKey="bs"
            language="Croatian"
          />
        </Tab>
        <Tab
          eventKey="lang-en"
          className="langTab"
          title={
            <GB
              title="Great Britain"
              style={{ width: "20px", paddingBottom: "5px" }}
            ></GB>
          }
        >
          <LanguageTabContent
            translation={translation}
            setTranslation={setTranslation}
            font={font}
            item={item}
            clearInputs={clearInputs}
            langKey="en"
            language="English"
          />
        </Tab>
        <Tab
          eventKey="lang-de"
          title={
            <DE
              title="Germany"
              style={{ width: "20px", paddingBottom: "5px" }}
            ></DE>
          }
        >
          <LanguageTabContent
            translation={translation}
            setTranslation={setTranslation}
            item={item}
            clearInputs={clearInputs}
            langKey="de"
            language="German"
          />
        </Tab>
        <Tab
          eventKey="lang-es"
          title={
            <ES
              title="Spain"
              style={{ width: "20px", paddingBottom: "5px" }}
            ></ES>
          }
        >
          <LanguageTabContent
            translation={translation}
            setTranslation={setTranslation}
            item={item}
            clearInputs={clearInputs}
            langKey="es"
            language="Spanish"
          />
        </Tab>
        <Tab
          eventKey="lang-fr"
          title={
            <FR
              title="France"
              style={{ width: "20px", paddingBottom: "5px" }}
            ></FR>
          }
        >
          <LanguageTabContent
            translation={translation}
            setTranslation={setTranslation}
            item={item}
            clearInputs={clearInputs}
            langKey="fr"
            language="French"
          />
        </Tab>
        <Tab
          eventKey="lang-it"
          title={
            <IT
              title="Italy"
              style={{ width: "20px", paddingBottom: "5px" }}
            ></IT>
          }
        >
          <LanguageTabContent
            translation={translation}
            setTranslation={setTranslation}
            item={item}
            clearInputs={clearInputs}
            langKey="it"
            language="Italian"
          />
        </Tab>
        <Tab
          eventKey="lang-tr"
          title={
            <TR
              title="Turkie"
              style={{ width: "20px", paddingBottom: "5px" }}
            ></TR>
          }
        >
          <LanguageTabContent
            translation={translation}
            setTranslation={setTranslation}
            item={item}
            clearInputs={clearInputs}
            langKey="tr"
            language="Turkish"
          />
        </Tab>
      </Tabs>
      <hr></hr>
    </>
  );
}
