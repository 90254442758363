import React, { useState, useContext, useRef, useEffect } from "react";
import { Button, Form, Popover, Overlay, FormLabel } from "react-bootstrap";
import { RiPlayListAddFill } from "react-icons/ri";
import { toast } from "react-toastify";
import CategoryPopover from "../components/CategoryPopover";
import { addCategory, addMenuItems, updateMenuItem } from "../apis";
import AuthContext from "../contexts/AuthContext";
import ImageDropzone from "./ImageDropzone";
import LanguageTabs from "../components/LanguageTabs";

const MenuItemForm = ({
  place,
  onDone,
  item,
  bgImage,
  setBgImage,
  categoryPriority,
  setCategoryPriority,
  mainCategories,
  categoryIsAvailable,
  setCategoryIsAvailable
}) => {
  const [categoryFormShow, setCategoryFormShow] = useState(false);
  const [parent_category, setParentCategory] = useState("");
  const [category, setCategory] = useState(item.category || {});
  const [itemPriority, setItemPriority] = useState(item.priority);
  const [price, setPrice] = useState(item.price || 0);
  const [image, setImage] = useState(item.image);
  const [clearInputs, setClearInputs] = useState(false);
  const [isAvailable, setIsAvailable] = useState(
    item.is_available === undefined ? true : !!item.is_available
  );
  const [isAdding, setIsAdding] = useState(false);

  const [variantArray, setVariantArray] = useState(item.variants);
  const [variants, setVariants] = useState([]);
  const [prices, setPrices] = useState([]);

  const [selectedVariantIndex, setSelectedVariantIndex] = useState(-1);
  const [item_type, setItemType] = useState(item.type);

  const [editVariant, setEditVariant] = useState("");
  const [editPrice, setEditPrice] = useState("");

  const target = useRef(null);
  const auth = useContext(AuthContext);

  const onAddCategory = async () => {
    const json = await addCategory(
      {
        priority: categoryPriority,
        parent_category: parent_category,
        background_image: bgImage,
        ...category,
        place: place.id,
      },
      auth.token
    );

    if (json) {
      toast(`Category ${json.name} was created.`, { type: "success" });
      setParentCategory(null);
      setBgImage(json.background_image);
      setCategoryPriority(json.priority);
      setCategoryFormShow(false);
      onDone();
    }
  };

  const onAddMenuItems = async () => {
    const json = await addMenuItems(
      {
        place: place.id,
        ...item,
        category: category,
        price,
        priority: itemPriority,
        image,
        is_available: isAvailable,
        type: item_type,
        variants: variantArray,
      },
      auth.token
    );

    if (json) {
      toast(`Menu Item ${json.name} was created`, { type: "success" });
      setCategory("");
      setPrice(0);
      setImage("");
      setIsAvailable(true);
      setItemPriority(0);
      setClearInputs(true);
      onDone();
    }
  };

  const onUpdateMenuItem = async () => {
    const json = await updateMenuItem(
      item.id,
      {
        place: place.id,
        ...item,
        category: category,
        priority: itemPriority,
        price,
        image,
        is_available: isAvailable,
        type: item_type,
        variants: variantArray,
      },
      auth.token
    );

    if (json) {
      toast(`Menu Item ${json.name} was updated`, { type: "success" });
      setItemPriority(json.priority);
      setCategory(json.category);
      setPrice(json.price);
      setItemType(json.type);
      setImage("");
      onDone();
    }
  };

  useEffect(() => {
    item.price = price;
    item.category = category;
  }, [price, category]);

  function renderCategories(categories, indentation = "") {
    return categories.map((category) => (
      <option key={category.id} value={category.id}>
        {`${indentation} ${category.name_bs}`}
      </option>
    ));
  }

  function renderNestedCategories(categories, indentation = "") {
    return categories.map((category) => (
      <React.Fragment key={category.id}>
        {renderCategories([category], indentation)}
        {category.children &&
          renderNestedCategories(category.children, `${indentation}-`)}
      </React.Fragment>
    ));
  }

  const handleFieldChange = (index, field, value) => {
    if (field === "variant") {
      const updatedVariants = [...variants];
      updatedVariants[index] = value;
      setVariants(updatedVariants);
    } else if (field === "price") {
      const updatedPrices = [...prices];
      updatedPrices[index] = value;
      setPrices(updatedPrices);
    }
  };

  const addNewVariant = () => {
    setIsAdding(true);
    setVariants([...variants, ""]);
    setPrices([...prices, ""]);
  };

  const cancelVariant = () => {
    if (isAdding) {
      setIsAdding(false);
      setVariants([]);
      setPrices([]);
    } else if (selectedVariantIndex !== -1) {
      setSelectedVariantIndex(-1);
      setEditVariant("");
      setEditPrice("");
    }
  };

  const handleDeleteVariant = (index) => {
    const c = window.confirm("Are you sure?");
    if (c) {
      setVariantArray((prevArray) => {
        const newVariants = [...prevArray];
        if (newVariants.length > 1) {
          newVariants.splice(index, 1);
          return newVariants;
        } else return null;
      });
    }
  };

  const handleEditChange = (field, value) => {
    if (field === "variant") {
      setEditVariant(value);
    } else if (field === "price") {
      setEditPrice(value);
    }
  };

  const handleSaveVariant = () => {
    if (selectedVariantIndex !== -1) {
      const updatedVariant = {
        ...variantArray[selectedVariantIndex],
        variant: editVariant,
        price: editPrice,
      };
      const updatedVariants = [...variantArray];
      updatedVariants[selectedVariantIndex] = updatedVariant;

      setVariantArray(updatedVariants);
      setSelectedVariantIndex(-1);
      setEditVariant("");
      setEditPrice("");
    } else {
      const newObject = variants.map((variant, index) => ({
        variant: variant,
        price: prices[index],
      }));

      setIsAdding(false);
      setVariantArray((prevArray) => {
        if (Array.isArray(prevArray)) {
          return [...prevArray, ...newObject];
        } else {
          return newObject;
        }
      });
      setVariants([]);
      setPrices([]);
    }
  };

  useEffect(() => {
    if (selectedVariantIndex !== -1) {
      const selectedVariant = variantArray[selectedVariantIndex];
      setEditVariant(selectedVariant?.variant || "");
      setEditPrice(selectedVariant?.price || "");
    }
  }, [selectedVariantIndex, variantArray]);

  return (
    <div>
      {/* CATEGORIES FORM */}
      <Form.Group>
        <Form.Label>Category</Form.Label>
        <div className="d-flex align-items-center">
          <Form.Control
            as="select"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <>
              <option>Main category</option>
              {mainCategories && renderNestedCategories(mainCategories)}
            </>
          </Form.Control>

          <Button
            ref={target}
            variant="link"
            onClick={() => {
              setCategoryFormShow(true);
              setCategory({});
            }}
          >
            <RiPlayListAddFill size={25} />
          </Button>
          <Overlay
            show={categoryFormShow}
            target={target.current}
            placement="bottom"
            rootClose
            onHide={() => setCategoryFormShow(false)}
          >
            <Popover id="popover-contained">
              <Popover.Title as="h3">Category</Popover.Title>
              <Popover.Content>
                <CategoryPopover
                  onAddCategory={onAddCategory}
                  item={item}
                  category={category}
                  bgImage={bgImage}
                  setBgImage={setBgImage}
                  categoryPriority={categoryPriority}
                  setCategoryPriority={setCategoryPriority}
                  place={place}
                  parent_category={parent_category}
                  setParentCategory={setParentCategory}
                  categoryIsAvailable = {categoryIsAvailable}
                  setCategoryIsAvailable = {setCategoryIsAvailable}
                />
              </Popover.Content>
            </Popover>
          </Overlay>
        </div>
      </Form.Group>

      {/* MENU ITEMS FORM */}
      <LanguageTabs
        place={place}
        key={item.id}
        item={item}
        clearInputs={clearInputs}
        setItemPriority={setItemPriority}
      />
      {isAdding ? (
        <div style={{ marginBottom: "10px" }}>
          {variants.map((variant, index) => (
            <>
              <div
                key={index}
                style={{ display: "grid", marginBottom: "10px" }}
              >
                <Form.Group>
                  <Form.Label>Size:</Form.Label>
                  <Form.Control
                    id={`variantInput-${index}`}
                    type="text"
                    value={variant}
                    onChange={(e) =>
                      handleFieldChange(index, "variant", e.target.value)
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Price:</Form.Label>
                  <Form.Control
                    id={`priceInput-${index}`}
                    type="number"
                    value={prices[index]}
                    onChange={(e) =>
                      handleFieldChange(index, "price", e.target.value)
                    }
                  />
                </Form.Group>
              </div>
              <button
                className="btn btn-primary mr-1"
                onClick={handleSaveVariant}
              >
                Save Size
              </button>
              <button className="btn btn-danger" onClick={cancelVariant}>
                Cancel
              </button>
            </>
          ))}
        </div>
      ) : (
        <>
          <div>
            <FormLabel>Size</FormLabel>
          </div>
          <Button
            style={{ marginBottom: "10px" }}
            variant="standard"
            type="submit"
            onClick={addNewVariant}
          >
            Add Size
          </Button>
          {variantArray && variantArray.length > 0 ? (
            selectedVariantIndex === -1 ? (
              <Form.Group>
                <Form.Label>Sizes</Form.Label>
                <ul>
                  {variantArray
                    .sort((a, b) => a.price - b.price)
                    .map((object, index) => (
                      <li
                        key={index}
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "30px",
                            alignItems: "center",
                            placeContent: "space-between",
                          }}
                        >
                          <div
                            style={{ maxWidth: "200px"}}
                          >
                            Size: {object.variant}, Price: {object.price}
                          </div>
                          <div style={{ display: "flex", gap: "5px" }}>
                            <button
                              className="btn btn-primary"
                              onClick={() => setSelectedVariantIndex(index)}
                            >
                              Edit
                            </button>
                            <button
                              type="submit"
                              className="btn btn-danger"
                              onClick={() => handleDeleteVariant(index)}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </Form.Group>
            ) : (
              <div>
                <Form.Group>
                  <Form.Label>Edit Size:</Form.Label>
                  <Form.Control
                    id="editVariantInput"
                    type="text"
                    value={editVariant}
                    onChange={(e) =>
                      handleEditChange("variant", e.target.value)
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Edit Price:</Form.Label>
                  <Form.Control
                    id="editPriceInput"
                    type="number"
                    value={editPrice}
                    onChange={(e) => handleEditChange("price", e.target.value)}
                  />
                </Form.Group>
                <div
                  style={{ display: "flex", gap: "5px", marginBottom: "10px" }}
                >
                  <button
                    className="btn btn-primary"
                    onClick={handleSaveVariant}
                  >
                    Update
                  </button>
                  <button className="btn btn-danger" onClick={cancelVariant}>
                    Cancel
                  </button>
                </div>
              </div>
            )
          ) : (
            <Form.Group>
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Form.Group>
          )}
        </>
      )}
      <Form.Group>
        <Form.Label> Select type </Form.Label>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="exampleRadios"
            id="exampleRadios1"
            value="bar"
            checked={item_type === "bar"}
            onClick={(e) => setItemType(e.target.value)}
          />
          <label class="form-check-label" for="exampleRadios1">
            Bar
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="exampleRadios"
            id="exampleRadios2"
            value="kitchen"
            checked={item_type === "kitchen"}
            onClick={(e) => setItemType(e.target.value)}
          />
          <label class="form-check-label" for="exampleRadios2">
            Kitchen
          </label>
        </div>
      </Form.Group>
      <Form.Group>
        <Form.Label>Image</Form.Label>
        <ImageDropzone value={image} onChange={setImage} />
      </Form.Group>
      <Form.Group>
        <Form.Check
          type="checkbox"
          label="Is available?"
          checked={isAvailable}
          onChange={(e) => setIsAvailable(e.target.checked)}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Priority</Form.Label>
        <Form.Control
          className="priority"
          type="number"
          placeholder="If you set priority to 1, product shows on the top"
          defaultValue={itemPriority}
          value={itemPriority}
          onChange={(e) => setItemPriority(e.target.value)}
        />
      </Form.Group>

      <Button
        variant="standard"
        block
        onClick={item.id ? onUpdateMenuItem : onAddMenuItems}
      >
        {item.id ? "Update Menu Item" : "+ Add Menu Item"}
      </Button>
    </div>
  );
};

export default MenuItemForm;
