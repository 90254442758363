import { Button, Form, Tab, Tabs } from "react-bootstrap";
import { HR, DE, FR, GB, ES, IT, TR } from "country-flag-icons/react/3x2";
import React, { useState, useEffect } from "react";
import ImageDropzone from "../containers/ImageDropzone";
import styled from "styled-components";


const CategoryTabs = ({ langKey, language, category }) => {
  const [name, setName] = useState(category[`name${langKey}`] ?? "");

  useEffect(() => {
    category[`name${langKey}`] = name;
  }, [name]);

  return (
    <Form.Group>
      <Form.Label>Category name on {language}</Form.Label>
      <Form.Control
        type="text"
        placeholder={`Category Name on ${language}`}
        value={name}
        defaultValue={name}
        name={`name${langKey}`}
        key={`name${langKey}`}
        onChange={(e) => setName(e.target.value)}
      />
    </Form.Group>
  );
};

const Container = styled.div`
  opacity: ${({ active }) => (active ? 1 : 0.6)};
`
export default function CategoryPopover({
  onAddCategory,
  place,
  onUpdateCategory,
  category,
  bgImage,
  setBgImage,
  setCategoryPriority,
  setParentCategory,
  parent_category,
  isAvailable,
  setIsAvailable,
}) {
  const mainCategories = [];

  
  
  if (place && place.categories) {
    const data = place.categories;
    const childMap = data.reduce((map, child) => {
      return {
        ...map,
        [child.id]: {
          ...child,
        },
      };
    }, {});

    Object.values(childMap).forEach((child) => {
      if (child.parent_category) {
        if (childMap[child.parent_category]) {
          const parent = childMap[child.parent_category];
          if (!parent.children) {
            parent.children = [];
          }
          parent.children.push(child);
        }
      } else {
        mainCategories.push(child);
      }
    });
  }

  useEffect(() => {
    setCategoryPriority();
  }, []);

  useEffect(() => {
    setBgImage(category.background_image);
  }, []);

  function renderCategories(categories, indentation = "") {
    return categories.map((category) => (
      <>
        <option key={category.id} value={category.id}>
          {`${indentation} ${category.name_bs}`}
        </option>
        {category.children &&
          renderCategories(category.children, `${indentation}-`)}
      </>
    ));
  }

  return (
    <div>
      <Tabs
        id="controlled-tab-example"
        className="mb-3"
        style={{ display: "grid", gridAutoFlow: "column", textAlign: "center" }}
      >
        <Tab
          eventKey="lang-en"
          title={<GB title="Great Britain" style={{ width: "25px" }}></GB>}
        >
          <CategoryTabs category={category} langKey="_en" language="English" />
        </Tab>
        <Tab
          eventKey="lang-bs"
          title={
            <HR title="Croatia" style={{ width: "25px" }}></HR>
          }
        >
          <CategoryTabs category={category} langKey="_bs" language="Croatian" />
        </Tab>
        <Tab
          eventKey="lang-de"
          title={<DE title="Germany" style={{ width: "25px" }}></DE>}
        >
          <CategoryTabs category={category} langKey="_de" language="German" />
        </Tab>
        <Tab
          eventKey="lang-es"
          title={<ES title="Spain" style={{ width: "25px" }}></ES>}
        >
          <CategoryTabs category={category} langKey="_es" language="Spanish" />
        </Tab>
        <Tab
          eventKey="lang-fr"
          title={<FR title="France" style={{ width: "25px" }}></FR>}
        >
          <CategoryTabs category={category} langKey="_fr" language="French" />
        </Tab>
        <Tab
          eventKey="lang-it"
          title={<IT title="Italy" style={{ width: "25px" }}></IT>}
        >
          <CategoryTabs category={category} langKey="_it" language="Italian" />
        </Tab>
        <Tab
          eventKey="lang-tr"
          title={<TR title="Turkie" style={{ width: "25px" }}></TR>}
        >
          <CategoryTabs category={category} langKey="_tr" language="Turkish" />
        </Tab>
      </Tabs>
      <Form.Label> Category Background Image</Form.Label>
      <ImageDropzone
        style={{ width: "100%", height: "100%" }}
        value={bgImage}
        onChange={setBgImage}
      />
      <Form.Label>Choose parent category</Form.Label>
      <Form.Control
        as="select"
        value={category.parent_category}
        onChange={(e) => setParentCategory(e.target.value)}
      >
        <>
          <option value={null}>{null}</option>
          {mainCategories && renderCategories(mainCategories)}
        </>
      </Form.Control>
      <Form.Label>Priority</Form.Label>
      <Form.Control
        className="priority"
        type="number"
        placeholder="If you set priority to 1, it will show on the top"
        defaultValue={category.priority}
        onChange={(e) => setCategoryPriority(e.target.value)}
      />
      {category.is_available !== undefined && (
       <Form.Check
       style={{marginTop: '20px', marginBottom: '20px'}}
          type="checkbox"
          label="Is available?"
          defaultChecked={category.is_available}
          onChange={(e) => setIsAvailable(e.target.checked)}
        />)}
      <Button
        variant="standard"
        block
        onClick={category.id ? onUpdateCategory : onAddCategory}
      >
        {category.id ? "Update category" : "+ Add category"}
      </Button>
    </div>
  );
}
