import { IoMdArrowBack } from "react-icons/io";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import { ChromePicker } from "react-color";
import styled from "styled-components";
import AuthContext from "../contexts/AuthContext";
import { toast } from "react-toastify";
import ImageDropzone from "../containers/ImageDropzone";
import MenuItem from "../components/MenuItem";
import { DE, GB, ES, FR, IT, TR, HR } from "country-flag-icons/react/3x2";

import { fetchPlace, updatePlace } from "../apis";
import MainLayout from "../layouts/MainLayout";


const Panel = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05);
`;

const MenuSettings = () => {
  const [place, setPlace] = useState({});
  const [font, setFont] = useState("");
  const [color, setColor] = useState("");
  const [background_color, setBgColor] = useState("");
  const [color_1, setTextColor] = useState("#000000");
  const [color_2, setBorderColor] = useState("#000000");
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(place.image);
  const [background_image, setBackgroundImage] = useState(
    place.background_image
  );
  const [number_of_tables, setNumberOfTables] = useState(
    place.number_of_tables
  );

  const [instagram_url, setInstagram] = useState("");
  const [korpa_url, setKorpa] = useState("");
  const [glovo_url, setGlovo] = useState("");
  const [client_url, setWeb] = useState("");
  const [enabled_languages, setEnabledLanguages] = useState([]);
  const [ordering_system, setOrderingSystem] = useState(place.ordering_system);
  const [password, setPlacePassword] = useState("");

  const params = useParams();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  const onBack = () => navigate(`/places/${params.id}`);

  const onFetchPlace = async () => {
    const json = await fetchPlace(params.id);
    if (json) {
      setPlace(json);
      setFont(json.font);
      setColor(json.color);
      setTextColor(json.color_1);
      setBorderColor(json.color_2);
      setBgColor(json.background_color);
      setInstagram(json.instagram_url);
      setKorpa(json.korpa_url);
      setGlovo(json.glovo_url);
      setWeb(json.client_url);
      setImage(json.image);
      setNumberOfTables(json.number_of_tables);
      setBackgroundImage(json.background_image);
      setEnabledLanguages(json.enabled_languages);
      setOrderingSystem(json.ordering_system);
      setPlacePassword(json.password);
    }
  };

  const onUpdatePlace = async () => {
    setLoading(true);
    const json = await updatePlace(
      place.id,
      {
        font,
        color,
        instagram_url,
        korpa_url,
        glovo_url,
        client_url,
        image,
        color_1,
        color_2,
        background_color,
        background_image,
        enabled_languages,
        number_of_tables,
        ordering_system,
        password,
      },
      auth.token
    );
    if (json) {
      toast("New settings is updated", { type: "success" });
      setPlace(json);
      setLoading(false);
    }
  };

  useEffect(() => {
    onFetchPlace();
  }, []);

  const Container = styled.div`
    b,
    p {
      ${({ font }) => font && `font-family: ${font};`}
    }
  `;

  const Place = styled.div`
    text-align: center;
    img {
      border-radius: 5px;
      margin-bottom: 20px;
    }
  `;

  const handleClick = (e) => {
    const { value, checked } = e.target;
    let prm = place;
    prm.enabled_languages = enabled_languages;

    if (checked) {
      setEnabledLanguages([...enabled_languages, value]);
      setPlace(prm);
    } else {
      setEnabledLanguages(enabled_languages.filter((e) => e !== value));
      setPlace(prm);
    }
  };
  return (
    <MainLayout>
      <div className="d-flex align-items-center mb-4">
        <Button variant="link" onClick={onBack}>
          <IoMdArrowBack size={25} color="black" />
        </Button>
        <h3 className="mb-0 mr-2 ml-2">Menu Settings</h3>
      </div>

      <Row>
        {/* LEFT SIDE */}
        <Col md={4}>
          <Panel>
            <Form.Group>
              <Form.Label>Font</Form.Label>
              <Form.Control
                as="select"
                value={font}
                onChange={(e) => setFont(e.target.value)}
              >
                <option>Inter</option>
                <option>Nunito</option>
                <option>Open Sans</option>
                <option>Montserrat</option>
                <option>Poppins</option>
                <option>Delicious Handrawn</option>
                <option>Bebas Neue</option>
                <option>Poppins</option>
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Font Color</Form.Label>
              <ChromePicker
                color={color}
                onChange={(value) => setColor(value.hex)}
                disableAlpha
                width="100%"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Background Color</Form.Label>
              <ChromePicker
                color={background_color}
                onChange={(value) => setBgColor(value.hex)}
                value={background_color}
                disableAlpha
                width="100%"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Online Menu text color</Form.Label>
              <ChromePicker
                color={color_1}
                onChange={(value) => setTextColor(value.hex)}
                value={color_1}
                disableAlpha
                width="100%"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Border Color</Form.Label>
              <ChromePicker
                color={color_2}
                onChange={(value) => setBorderColor(value.hex)}
                value={color_2}
                disableAlpha
                width="100%"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Image</Form.Label>
              <ImageDropzone
                style={{ width: "100%", height: "100%" }}
                value={image}
                onChange={setImage}
              />
              <Form.Label>Background Image</Form.Label>
              <ImageDropzone
                style={{ width: "100%", height: "100%" }}
                value={background_image}
                onChange={setBackgroundImage}
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                type="checkbox"
                label="Ordering System"
                checked={ordering_system}
                onChange={(e) => setOrderingSystem(!ordering_system)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="text"
                placeholder="Place password"
                value={password}
                onChange={(e) => setPlacePassword(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Website</Form.Label>
              <Form.Control
                type="text"
                placeholder="Website link"
                value={client_url}
                onChange={(e) => setWeb(e.target.value)}
              ></Form.Control>
              <Form.Label>Instagram</Form.Label>
              <Form.Control
                type="text"
                placeholder="Instagram link"
                value={instagram_url}
                onChange={(e) => setInstagram(e.target.value)}
              ></Form.Control>
              <Form.Label>Korpa</Form.Label>
              <Form.Control
                type="text"
                placeholder="Korpa link"
                value={korpa_url}
                onChange={(e) => setKorpa(e.target.value)}
              ></Form.Control>
              <Form.Label>Glovo</Form.Label>
              <Form.Control
                type="text"
                placeholder="Glovo link"
                value={glovo_url}
                onChange={(e) => setGlovo(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <p>Choose languages you want to use:</p>
            <Form.Group style={{ display: "grid", gridAutoFlow: "column" }}>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <HR style={{ width: "20px", paddingBottom: "5px" }}></HR>
                <Form.Check
                  type="checkbox"
                  value="bs"
                  checked={enabled_languages.includes("bs")}
                  onChange={handleClick}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <DE style={{ width: "20px", paddingBottom: "5px" }}></DE>
                <Form.Check
                  type="checkbox"
                  value="de"
                  checked={enabled_languages.includes("de")}
                  onChange={handleClick}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <GB style={{ width: "20px", paddingBottom: "5px" }}></GB>
                <Form.Check
                  type="checkbox"
                  value="en"
                  checked={enabled_languages.includes("en")}
                  onChange={handleClick}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <ES style={{ width: "20px", paddingBottom: "5px" }}></ES>
                <Form.Check
                  type="checkbox"
                  value="es"
                  checked={enabled_languages.includes("es")}
                  onChange={handleClick}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <FR style={{ width: "20px", paddingBottom: "5px" }}></FR>
                <Form.Check
                  type="checkbox"
                  value="fr"
                  checked={enabled_languages.includes("fr")}
                  onChange={handleClick}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <IT style={{ width: "20px", paddingBottom: "5px" }}></IT>
                <Form.Check
                  type="checkbox"
                  value="it"
                  checked={enabled_languages.includes("it")}
                  onChange={handleClick}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <TR style={{ width: "20px", paddingBottom: "5px" }}></TR>
                <Form.Check
                  type="checkbox"
                  value="tr"
                  checked={enabled_languages.includes("tr")}
                  onChange={handleClick}
                />
              </div>
            </Form.Group>

            <Button
              className="mt-4"
              variant="standard"
              block
              onClick={onUpdatePlace}
              disabled={loading}
            >
              Save Setings
            </Button>
          </Panel>
        </Col>

        {/* RIGHT SIDE */}
        <Col md={8}>
          <Container font={font}>
            <Place>
              <img src={place.image} width="30%" />
              <h3>
                <b>{place.name}</b>
              </h3>
            </Place>
            {place?.categories
              ?.filter(
                (category) =>
                  category.menu_items.filter((i) => i.is_available).length
              )
              .map((category) => (
                <div key={category.id} className="mt-5">
                  <h4 className="mb-4">
                    <b>{category.name_bs}</b>
                  </h4>
                  <div style={{ display: "grid", rowGap: "20px" }}>
                    {category.menu_items
                      .filter((item) => item.is_available)
                      .map((item) => (
                        <MenuItem
                          key={item.id}
                          item={item}
                          color={color}
                          bgColor={background_color}
                        />
                      ))}
                  </div>
                </div>
              ))}
          </Container>
        </Col>
      </Row>
    </MainLayout>
  );
};

export default MenuSettings;
