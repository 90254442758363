const translations = {
  en: {
    showOrder: "Show order",
    total: "TOTAL",
    chooseTable: "CHOOSE A TABLE",
    note: "NOTE",
    placeOrder: "ORDER",
    size: "Size",
    quantity: "Quantity",
    order: "ORDER",
    chooseSize: "Choose size",
    flavor: "Flavor",
    orderRecieved: "Your order has been received!",
    backToMenu: "BACK TO MENU",
    choose: "Choose"
  },
  bs: {
    showOrder: "Prikaži narudžbu",
    total: "UKUPNO",
    chooseTable: "ODABERI STO",
    note: "NAPOMENA",
    placeOrder: "NARUČI",
    size: "Veličina",
    quantity: "Količina",
    order: "NARUDŽBA",
    chooseSize: "Odaberi veličinu",
    flavor: "Okus",
    orderRecieved: "Vaša narudžba je primljena!",
    backToMenu: "NAZAD NA MENI",
    choose: "Odaberi"
  },
  de: {
    showOrder: "Zeige bestellung",
    total: "INSGESAMT",
    chooseTable: "WÄHLE EINEN TISCH",
    note: "HINWEIS",
    placeOrder: "BESTELLUNG",
    size: "Größe",
    quantity: "Menge",
    order: "BESTELLUNG",
    chooseSize: "Größe wählen",
    flavor: "Geschmack",
    orderRecieved: "Ihre Bestellung wurde erhalten!",
    backToMenu: "ZURÜCK ZUM MENÜ",
    choose: "Wählen"
  },
  es: {
    showOrder: "Mostrar orden",
    total: "TOTAL",
    chooseTable: "ELIGE UNA MESA",
    note: "NOTA",
    placeOrder: "ORDERNAR",
    size: "Tamaño",
    quantity: "Cantidad",
    order: "ORDEN",
    chooseSize: "Elige el tamaño",
    flavor: "Sabor",
    orderRecieved: "¡Se ha recibido su pedido!",
    backToMenu: "VOLVER AL MENÚ",
    choose: "Elegir"
  },
  fr: {
    showOrder: "Afficher la commande",
    total: "TOTAL",
    chooseTable: "CHOISISSEZ UNE TABLE",
    note: "NOTE",
    placeOrder: "COMMANDER",
    size: "Taille",
    quantity: "Quantité",
    order: "COMMANDE",
    chooseSize: "Choisissez la taille",
    flavor: "Saveur",
    orderRecieved: "Votre commande a été reçue !",
    backToMenu: "RETOUR AU MENU",
    choose: "Choisir"
  },
  it: {
    showOrder: "Mostra ordine",
    total: "TOTALE",
    chooseTable: "SCEGLI UN TAVOLO",
    note: "NOTA",
    placeOrder: "ORDINARE",
    size: "Dimensione",
    quantity: "Quantita",
    order: "ORDINE",
    chooseSize: "Scegli la taglia",
    flavor: "Gusto",
    orderRecieved: "Il vostro ordine è stato ricevuto!",
    backToMenu: "INDIETRO AL MENU",
    choose: "Scegliere"
  },
  tr: {
    showOrder: "Siparişi göster",
    total: "TOPLAM",
    chooseTable: "BİR MASA SEÇ",
    note: "NOT",
    placeOrder: "SİPARİŞ VER",
    size: "Boyut",
    quantity: "Miktar",
    order: "SİPARİŞ",
    chooseSize: "Boyut seçin",
    flavor: "Tat",
    orderRecieved: "Siparişiniz alındı!",
    backToMenu: "MENÜYE GERİ DÖN",
    choose: "Seç"
  },
};
export default translations;
