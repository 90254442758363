import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import LanguageContext from "../contexts/LanguageContext";
import { Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { addOrder } from "../apis";
import AuthContext from "../contexts/AuthContext";
import { toast } from "react-toastify";
import arrow from "../photos/arrow.png";
import { Modal } from "react-bootstrap";
import OrderAcceptModal from "./OrderAcceptModal";
import { MdFileDownloadDone } from "react-icons/md";
import { BiSolidMinusCircle, BiSolidPlusCircle } from "react-icons/bi";
import Translation from "./Translation";

const Container = styled.div`
  border-radius: 10px;
  background-color: white;
  height: 80px;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  opacity: ${({ active }) => (active ? 1 : 0.6)};
  > div:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-size: cover;
  }
  > div:last-child {
  }
`;

const ShoppingCart = ({
  items,
  place,
  onAdd,
  onRemove,
  color,
  setShoppingCart,
  shoppingCart,
}) => {
  const totalPrice = useMemo(
    () =>
      items
        .map((i) => i.quantity * parseInt(i.price))
        .reduce((a, b) => a + b, 0),
    [items]
  );
  const formattedTotalPrice = totalPrice.toFixed(2);
  const params = useParams();
  const navigate = useNavigate();

  const [tables, setTables] = useState([]);
  const [table, setTable] = useState();
  const [napomena, setNapomena] = useState("");
  const [writeNapomena, setWriteNapomena] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const auth = useContext(AuthContext);
  const defaultNote = "Nema napomene";

  useEffect(() => {
    const numberOfTables = place.number_of_tables;
    const tableData = [];

    for (let i = 1; i <= numberOfTables; i++) {
      tableData.push({
        id: i,
        name: `Table ${i}`,
      });
    }
    setTables(tableData);
  }, []);

  const clearShoppingCart = () => {
    const emptyCart = {};
    localStorage.setItem("shoppingCart", JSON.stringify(emptyCart));
    setShoppingCart(emptyCart);
  };

  const { language } = useContext(LanguageContext);

  const onWriteNapomena = () => {
    setWriteNapomena(true);
  };

  const onAddNapomena = () => {
    setWriteNapomena(false);
  };

  const onNapomenaChange = (event) => {
    setNapomena(event.target.value);
  };

  const onAddOrder = async () => {
    await addOrder(
      Date.now(),
      {
        place: place.id,
        table: table,
        note: napomena || defaultNote,
        detail: JSON.stringify(shoppingCart),
        payment_intent: "string",
        amount: JSON.stringify(totalPrice),
      },
      auth.token
    );
  };

  const handleOrder = () => {
    if (table) {
      setAcceptModal(true);
      clearShoppingCart();
      onAddOrder();
    } else toast("CHOOSE TABLE", { type: "error" });
  };

  const [selectedOption, setSelectedOption] = useState("");

  const handleTablePick = (e) => {
    setTable(e.target.value);
    setSelectedOption(e.target.value);
  };

  const { translate } = Translation({ language });

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${place.background_image})`,
          backgroundColor: "rgba(0,0,0, 0.3)",
          backgroundBlendMode: "darken",
          backgroundSize: "cover",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "30vh",
          backgroundPositionY: "center",
          width: "100vw",
          maxWidth: "480px",
        }}
      ></div>

      <b
        className="parentCategoryName"
        style={{ color: "white", fontSize: "23px" }}
        font={place.font}
      >
        {translate("order")}
      </b>
      <div
        style={{
          position: "relative",
          backgroundColor: `${place.background_color}`,
          paddingTop: "70px",
          minHeight: "60vh",
          overflowY: "auto",
          display: "grid",
          gap: "10px",
          alignContent: "start",
          paddingBottom: "300px",
        }}
        className="onlineMenu"
      >
        <img
          src={arrow}
          className="arrowLeft"
          alt="arrow"
          style={{
            position: "absolute",
            left: "5%",
            top: "30px",
            width: "30px",
            height: "25px",
            cursor: "pointer",
          }}
          onClick={() => navigate(-1)}
        ></img>
        {items.map((item) => (
          <div className="d-flex justify-content-center">
            <Container
              active={item.is_available}
              style={{ width: "70%", marginRight: "20px" }}
            >
              <Col
                xs={5}
                style={{
                  backgroundImage: `url(${item.image})`,
                  backgroundPositionY: "center",
                  backgroundPositionX: "center",
                  maxWidth: "35%",
                }}
              />
              <Col className="d-flex flex-column text-break justify-content-between position-relative">
                <div>
                  <div
                    style={{ minHeight: "40px" }}
                    className="d-flex justify-content-between flex-row align-items-center mb-2"
                  >
                    <b
                      className="itemName"
                      style={{
                        color,
                      }}
                    >
                      {item["name_" + language]}
                    </b>
                    <div className="d-flex"></div>
                  </div>
                </div>
                <div style={{ color, display: "flex" }}>
                  {item.variants && (
                    <span style={{width: "80px", height:'20px', overflow:'scroll' }} className="small">
                      <small>
                        {item.variants.length > 3
                          ? `${translate("flavor")} : ${item.variants}`
                          : `${translate("size")} : ${item.variants}`}
                      </small>
                    </span>
                  )}
                  <b
                    className="itemPrice"
                    style={{ color, fontSize: "15px", paddingLeft: "50px" }}
                  >
                    {item.price} €
                  </b>
                </div>
                <div className="d-flex justify-content-between align-items-end align-self-end">
                  {!item.is_available ? (
                    <small className="text-secondary">Not Available</small>
                  ) : null}
                </div>
              </Col>
            </Container>
            <div
              className="d-flex flex-column align-items-center"
              style={{ width: "20%" }}
            >
              <span className="d-flex">
                {translate("quantity")}: {item?.quantity}
              </span>
              <div className="d-flex">
                <BiSolidMinusCircle
                  className="kolicina-ikone"
                  width={30}
                  height={30}
                  onClick={() => onRemove(item)}
                  style={{ cursor: "pointer" }}
                />
                <BiSolidPlusCircle
                  className="kolicina-ikone"
                  width={30}
                  height={30}
                  onClick={() => onAdd(item)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          </div>
        ))}
        <div
          style={{
            position: "fixed",
            bottom: "0px",
            width: "100%",
            maxWidth: "480px",
          }}
        >
          <div
            style={{
              backgroundColor: "gray",
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
              display: "grid",
              alignContent: "center",
            }}
          >
            <div
              className="text-white"
              style={{
                padding: "10px",
                display: "flex",
                justifyContent: "center",
                gap: "7px",
              }}
            >
              {translate("total")}:<b>{formattedTotalPrice} €</b>
            </div>
            <div
              style={{
                backgroundColor: "white",
                height: "20vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <select
                value={selectedOption}
                onChange={handleTablePick}
                style={{
                  borderRadius: "20px",
                  border: "2px solid black",
                  display: "flex",
                  justifyContent: "center",
                  padding: "3px",
                  width: "170px",
                  cursor: "pointer",
                }}
              >
                {/* <img src={arrowDown} width={"20px"} height={"20px"} /> */}
                <option value="" disabled selected>
                  {translate("chooseTable")}
                </option>
                {tables.map((table) => (
                  <option
                    value={table.id}
                    // onClick={handleTablePick(table.target.value)}
                  >
                    {table.name}
                  </option>
                ))}
              </select>
              <div></div>
              <div
                style={{
                  display: "flex",
                  columnGap: writeNapomena ? "20px" : "40px",
                  justifyContent: "center",
                }}
              >
                {writeNapomena ? (
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "10px" }}
                  >
                    <div className="inputNapomenaDiv">
                      <input
                        placeholder="Send message"
                        autoFocus
                        style={{ width: "100%", borderRadius: "25px" }}
                        className="form-control"
                        type="text"
                        value={napomena}
                        onChange={onNapomenaChange}
                      ></input>
                    </div>
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        cursor: "pointer",
                      }}
                    >
                      <MdFileDownloadDone onClick={onAddNapomena} size="sm" />
                    </div>
                  </div>
                ) : (
                  <>
                    <button
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        borderRadius: "10px",
                        width: "130px",
                        border: "none",
                        padding: "5px",
                      }}
                      onClick={onWriteNapomena}
                    >
                      {translate("note")}
                    </button>
                    <button
                      onClick={handleOrder}
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        borderRadius: "10px",
                        width: "130px",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      {translate("placeOrder")}
                    </button>
                  </>
                )}
              </div>
            </div>
            <Modal
              id="itemModal"
              className="itemModal"
              show={acceptModal}
              onHide={() => navigate(-1)}
              centered
            >
              <div style={{ backgroundColor: "white", borderRadius: "40px" }}>
                <Modal.Body className="acceptModalBody">
                  <OrderAcceptModal
                    onDone={() => {
                      navigate(-1);
                    }}
                  />
                </Modal.Body>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShoppingCart;
